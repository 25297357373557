import React from "react"

const NamedDefault = ({ data }) => <>
  The next upcoming session of our Practice Dojo Q4 2024 series 
   is TBD.
{/*    <b>Saturday, September 7<sup>th</sup> at 
  1:00-2:30pm Pacific Time (4pm Eastern, 8pm UTC)</b>. <a 
  href="https://us02web.zoom.us/meeting/register/tZEvc-2qrDoqHNAFva8GMK_UTfaMrItyBsfB">Click 
  here to register and receive the meeting link.</a> */}
</>
export default NamedDefault;
